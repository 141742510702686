import { packer } from './binpacking💀/guillotine-packer.js'
import { VehicleTypeEnum } from '@apiTypes'
import type { Good } from '@apiTypes'
import bigLorryDefaultImage from '@images/ic_big_lorry_default.svg'
import bigLorryMutedImage from '@images/ic_big_lorry_muted.svg'
import bigLorrySelectedImage from '@images/ic_big_lorry_selected.svg'
import lkwDefaultImage from '@images/ic_lkw_default.svg'
import lkwMutedImage from '@images/ic_lkw_muted.svg'
import lkwSelectedImage from '@images/ic_lkw_selected.svg'
import lorryDefaultImage from '@images/ic_lorry_default.svg'
import lorryMutedImage from '@images/ic_lorry_muted.svg'
import lorrySelectedImage from '@images/ic_lorry_selected.svg'
import vanDefaultImage from '@images/ic_van_default.svg'
import vanMutedImage from '@images/ic_van_muted.svg'
import vanSelectedImage from '@images/ic_van_selected.svg'

export interface Vehicle {
  icon_default: string
  icon_muted: string
  icon_selected: string
  type: VehicleTypeEnum
  title: string
  description: string
  dimensions?: VehicleDimensions
  weight: number
  enabled?: boolean
}

export interface VehicleDimensions {
  width: number // in cm
  length: number // in cm
  height: number // in cm
}

export const vehicles: Vehicle[] = [
  {
    type: VehicleTypeEnum.VAN,
    title: 'VAN',
    description: 'up to 1,2 tons',
    icon_default: vanDefaultImage,
    icon_selected: vanSelectedImage,
    icon_muted: vanMutedImage,
    dimensions: { width: 200, length: 420, height: 200 },
    weight: 1000,
    enabled: true,
  },
  {
    type: VehicleTypeEnum.SMALL_LORRY,
    title: 'Lorry',
    description: 'up to 3,5 tons',
    icon_default: lorryDefaultImage,
    icon_selected: lorrySelectedImage,
    icon_muted: lorryMutedImage,
    dimensions: { width: 245, length: 600, height: 240 },
    weight: 2800,
    enabled: false,
  },
  {
    type: VehicleTypeEnum.BIG_LORRY,
    title: 'Big Lorry',
    description: 'up to 7 tons',
    icon_default: bigLorryDefaultImage,
    icon_selected: bigLorrySelectedImage,
    icon_muted: bigLorryMutedImage,
    dimensions: { width: 245, length: 700, height: 250 },
    weight: 5000,
    enabled: false,
  },
  {
    type: VehicleTypeEnum.LKW,
    title: 'LKW',
    description: 'up to 24 tons',
    icon_default: lkwDefaultImage,
    icon_selected: lkwSelectedImage,
    icon_muted: lkwMutedImage,
    dimensions: { width: 245, length: 1360, height: 270 },
    weight: 24000,
    enabled: false,
  },
]

interface Item {
  name: string
  width: number
  height: number
}

interface PackedItem {
  bin: number
  x: number
  y: number
  width: number
  height: number
  item: Item
}

type PackerResult = PackedItem[][] | null

export class BinPacking2D {
  private vehicle: Vehicle
  private readonly goods: Good[]

  constructor(vehicle: Vehicle, goods: Good[]) {
    this.vehicle = vehicle
    this.goods = goods
  }

  private calculateStackCount(good: Good): number {
    const count = good.count ?? 1
    if (!good.stackable || !this.vehicle.dimensions)
      return count
    const maxPerStack = Math.floor(this.vehicle.dimensions.height / (good.height ?? 1))

    return Math.ceil(count / maxPerStack)
  }

  public fitPackages(): { fits: boolean; fillPercentage: number } {
    if (!this.vehicle.dimensions)
      return this.createResult(false, 0)

    if (this.goods.length === 0)
      return this.createResult(true, 0)

    if (this.hasOversizedPackage())
      return this.createResult(false, 0)

    const items = this.prepareItemsForPacking()
    const packingResult = this.packItems(items)

    const allPacked = this.areAllItemsPacked(packingResult, items)
    const fillPercentage = this.calculateFillPercentage(packingResult ? packingResult[0] : [])

    return this.createResult(allPacked, fillPercentage)
  }

  private hasOversizedPackage(): boolean {
    for (const good of this.goods) {
      if (good.width > this.vehicle.dimensions.width || good.length > this.vehicle.dimensions.length)
        return true
    }

    return false
  }

  private prepareItemsForPacking(): Item[] {
    const items: Item[] = []

    this.goods.forEach(good => {
      const stackCount = this.calculateStackCount(good)
      for (let i = 0; i < stackCount; i++) {
        items.push({
          width: good.width,
          height: good.length,
        })
      }
    })

    return items
  }

  private packItems(items: Item[]): PackerResult {
    return packer({
      binWidth: this.vehicle.dimensions.width,
      binHeight: this.vehicle.dimensions.length,
      items,
    })
  }

  private areAllItemsPacked(packingResult: PackerResult, items: Item[]): boolean {
    return packingResult?.length === 1 && packingResult[0].length === items.length
  }

  private createResult(fits: boolean, fillPercentage: number): { fits: boolean; fillPercentage: number } {
    return { fits, fillPercentage }
  }

  private calculateFillPercentage(packedItems: PackedItem[]): number {
    if (!this.vehicle.dimensions)
      return 0

    const carFloorArea = this.vehicle.dimensions.length * this.vehicle.dimensions.width
    let occupiedArea = 0

    packedItems.forEach(item => {
      occupiedArea += item.width * item.height
    })

    return (occupiedArea / carFloorArea) * 100
  }
}
