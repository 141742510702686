<script setup lang="ts">
import { ref } from 'vue'
import type { Vehicle } from '@/utils/vehicle'

const props = defineProps({
  vehicle: {
    type: Object as PropType<Vehicle>,
    required: true,
  },
})

const vehicle = toRef(props, 'vehicle')
const selected = ref(false)

function select() {
  selected.value = true
}
</script>

<template>
  <div class="col-lg-3 col-4  flex-shrink-0 px-0">
    <div
      class="vehicle text-nowrap rounded-3"
      :class="{
        'cursor-pointer': vehicle.enabled,
        'cursor-disabled': !vehicle.enabled,
        'opacity-75': !vehicle.enabled,
        'disabled': !vehicle.enabled,
        'active': vehicle.enabled,
      }"
      @click="select"
    >
      <img
        v-if="!vehicle.enabled"
        class="vehicle-icon"
        :src="vehicle.icon_muted"
        :alt="vehicle.title"
      >
      <img
        v-else-if="vehicle.enabled"
        class="vehicle-icon cursor-pointer"
        :src="vehicle.icon_selected"
        :alt="vehicle.title"
      >
      <div class="fw-bolder mt-2">
        {{ vehicle.title }} - <span v-if="!vehicle.enabled" class="text-muted fw-light">{{ $t('Coming soon') }}</span>
      </div>
      <div>
        {{ vehicle.description }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/abstracts/variables';

.vehicle {
  background-color: white;
  margin: 0 .25rem;
  padding: 1.3rem 1.3rem;
  position: relative;
}

.active {
  background-color: $dark;
  color: white;
}
</style>
