import { capitalize } from 'vue'
import i18n from '@/plugins/i18n'
import { AllowedEnum, TransportUpdateStatusEnum, TransportVehicleTypeEnum } from '@apiTypes'
import type { CountryEnum, LTLCountry, Waypoint } from '@apiTypes'
import deliveryArrived from '@images/ic_mark_delivery.svg'
import pickupArrived from '@images/ic_mark_pickup.svg'
import loaded from '@images/ic_loaded.svg'
import unloaded from '@images/ic_unloaded.svg'
import truck from '@images/ic_truck_on_road.svg'
import LORRY from '@images/detail-LORRY.webp'
import BIG_LORRY from '@images/detail-BIG_LORRY.webp'
import VAN from '@images/detail-VAN.webp'
import LKW from '@images/detail-LKW.webp'

export function isLTLAllowed(waypoints: Waypoint[], currentTotalWeight: number, tailLift: boolean | undefined, ltlCountries: LTLCountry[]) {
  if (waypoints) {
    const pickupCountry = waypoints[0].country || undefined
    const pickupPostcode = waypoints[0].postcode || undefined
    const deliveryCountry = waypoints[1].country || undefined
    const deliveryPostcode = waypoints[1].postcode || undefined

    const checkingPickupResult = checkCountry(pickupCountry, pickupPostcode, ltlCountries)
    const checkingDeliveryResult = checkCountry(deliveryCountry, deliveryPostcode, ltlCountries)

    return checkLTL(checkingDeliveryResult, currentTotalWeight, tailLift) && checkLTL(checkingPickupResult, currentTotalWeight, tailLift)
  }
}

function checkCountry(country: CountryEnum | undefined, postcode: string | undefined, ltlCountries: LTLCountry[]) {
  if (country && !postcode) {
    const ltlCountry = ltlCountries.find(code => code.country === country)

    if (!ltlCountry)
      return {}

    return {
      whole_disallowed: ltlCountry.whole_disallowed,
      whole_partially: ltlCountry.whole_partially,
    }
  }
  else if (country && postcode) {
    const ltlCountry = ltlCountries.find(code => code.country === country)

    if (ltlCountry) {
      const disallowedPostcodes = ltlCountry?.regions.find(code => code.allowed === AllowedEnum.NotAllowed)
      const partiallyAllowedPostcodes = ltlCountry?.regions.find(code => code.allowed === AllowedEnum.PartiallyAllowed)

      let notAllowed = false
      let partiallyAllowed = false

      if (disallowedPostcodes?.postcodes)
        notAllowed = !!disallowedPostcodes.postcodes.find(code => postcode.startsWith(code))

      if (partiallyAllowedPostcodes?.postcodes && !notAllowed)
        partiallyAllowed = !!partiallyAllowedPostcodes.postcodes.find(code => postcode.startsWith(code))

      return {
        whole_disallowed: ltlCountry.whole_disallowed,
        whole_partially: ltlCountry.whole_partially,
        notAllowed,
        partiallyAllowed,
      }
    }
  }

  return {}
}

function checkLTL(result: any, currentTotalWeight: number, tailLift: boolean | undefined) {
  if (result.whole_disallowed || result.notAllowed)
    return false
  else if ((result.partiallyAllowed || result.whole_partially) && !tailLift)
    return currentTotalWeight <= 300
  else if (tailLift)
    return currentTotalWeight <= 250
  else
    return currentTotalWeight <= 500
}

export function getIcon(status: TransportUpdateStatusEnum): string {
  const iconMap = {
    [TransportUpdateStatusEnum.PICKUP_ARRIVED]: pickupArrived,
    [TransportUpdateStatusEnum.LOADED]: loaded,
    [TransportUpdateStatusEnum.ON_ROAD]: truck,
    [TransportUpdateStatusEnum.DELIVERY_ARRIVED]: deliveryArrived,
    [TransportUpdateStatusEnum.UNLOADED]: unloaded,
  }

  return iconMap[status]
}

export function getVehicleImage(vehicleType: TransportVehicleTypeEnum): string {
  const vehicleImageMap = {
    [TransportVehicleTypeEnum.CAR]: VAN,
    [TransportVehicleTypeEnum.VAN]: VAN,
    [TransportVehicleTypeEnum.SMALL_LORRY]: LORRY,
    [TransportVehicleTypeEnum.BIG_LORRY]: BIG_LORRY,
    [TransportVehicleTypeEnum.LKW]: LKW,
  }

  return vehicleImageMap[vehicleType]
}

export function formatStatus(status: string): string {
  return capitalize(status.toLowerCase().replace(/_/, ' '))
}

export function formatRemainingDistance(currentDistance: number): string {
  const { t } = i18n.global
  if (currentDistance) {
    const remainingDistance = Math.max(0, currentDistance)

    return `${remainingDistance}${t(' km remaining')}`
  }

  return ''
}
